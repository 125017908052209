<template>
  <div class="">
    <b-container fluid="xl">
      <div class="px-md-2">
        <b-row cols-md="2" cols="1">
          <b-col class="text-start pe-lg-5 pe-1 main-details"
            ><div>
              <img
                src="../../assets/mobilaeCatalog.png"
                class="mt-md-n5 book-img mx-auto text-center d-none d-md-block"
              />

              <h1 class="display-5 fw-stronger formSection__title">
                Maak uw badkamer weer veilig!
              </h1>

              <div class="d-none d-lg-block">
                <p class="l-height formSection__desc paragraph">
                  Maak veilig en comfortabel gebruik van uw badkamer. Kies voor
                  een lage instap, een antislipvloer of andere extra’s als een
                  ingebouwde handgreep, badlift of douchezitje. In onze gratis
                  brochure kunt u op uw gemak alles lezen over badkamers. Door
                  de brochure krijgt u een goed beeld van de mogelijkheden en
                  modellen, service en financiering.
                </p>
              </div>

              <div class="row d-flex d-lg-none">
                <p class="formSection__desc">
                  Maak veilig en comfortabel gebruik van uw badkamer. Kies voor
                  een lage instap, een antislipvloer of andere extra’s als een
                  ingebouwde handgreep, badlift of douchezitje. In onze gratis
                  brochure kunt u op uw gemak alles lezen over badkamers.
                </p>
                <div class="col-8">
                  <p class="formSection__desc">
                    Door de brochure krijgt u een goed beeld van de
                    mogelijkheden en modellen,service en financiering.
                  </p>
                </div>
                <div class="col-4">
                  <img
                    src="../../assets/desktop_sticker.png"
                    class="d-block d-md-none mbl-image"
                    height="100px"
                    width="auto"
                  />
                </div>
              </div>
              <h4 class="fw-stronger formSection__tipsHeader d-none d-md-block">
                Vraag onze brochure aan en ontdek:
              </h4>

              <ol class="d-none d-md-block">
                <li class="l-height mb-0 formSection__tips">
                  Tips en adviezen
                </li>
                <li class="l-height mb-0 formSection__tips">
                  Grootste assortiment in merken en modellen
                </li>
                <li class="l-height mb-0 formSection__tips">
                  Keuze in iedere prijsklasse
                </li>
              </ol>
            </div>
          </b-col>

          <b-card
            class="p-0 m-0 mt-0 mt-lg-5 formSection__details position-relative"
          >
            <div>
              <img
                src="../../assets/desktop_sticker.png"
                class="position-absolute desktop-sticker d-none d-lg-block"
                height="150px"
                width="auto"
              />
            </div>
            <div class="d-flex flex-column h-auto">
              <h3 class="fw-bold">
                Aangenaam kennis te maken! Dank u voor uw interesse in Mobilae.
              </h3>
              <p class="text-start">
                Wij hebben u de brochure per e-mail toegestuurd. Als u de e-mail
                niet in uw inbox vindt, is deze misschien in uw spam box terecht
                gekomen.
              </p>

              <p class="mt-3 text-start">
                U kunt de brochure ook direct digitaal bekijken via onderstaande
                button.
              </p>

              <b-button
                type="button"
                variant="primary"
                class="s-btn btn-lg fw-bold formSection__form__btn"
                @click="
                  openLink(
                    'https://s3-eu-west-1.amazonaws.com/cdn.mobilae.nl/emag/nl_badkamer_brochure/index.html'
                  )
                "
              >
                Bekijk de Mobilae Nederland brochure »
              </b-button>
              <b-button
                type="button"
                variant="primary"
                class="s-btn btn-lg fw-bold formSection__form__btn mt-2"
                @click="
                  openLink(
                    'https://s3-eu-west-1.amazonaws.com/cdn.mobilae.be/emag/be_badkamer_brochure/index.html'
                  )
                "
              >
                Bekijk de Mobilae België brochure »
              </b-button>

              <div class="mt-5 d-flex justify-content-start flex-column">
                <b class="text-start mb-3">Wat zijn de volgende stappen?</b>
                <p class="text-start">
                  Wij zijn blij dat u op zoek bent naar manieren om uw
                  mobiliteit, veiligheid en onafhankelijkheid te verbeteren!
                  Hebt u nog vragen en wilt u weten welke optie(s) het beste bij
                  u passen? Dat is begrijpelijk. Daarom nemen wij binnenkort
                  contact met u op om u stap voor stap te ondersteunen. Dit is
                  een standaard service die wij u graag aanbieden. Het is
                  helemaal gratis en zonder enige verplichting.
                </p>
              </div>
              <div class="d-flex justify-content-start flex-column">
                <p class="fw-bold text-start">
                  Mocht u niet kunnen wachten? Ons team zit voor u klaar op 0800
                  565 0800 (NL) of 09 296 55 33 (BE) om al uw vragen te
                  beantwoorden en u verder te voorzien van advies.
                </p>
              </div>
              <div class="m-auto">
                <img
                  v-if="profId != null"
                  referrerpolicy="no-referrer-when-downgrade"
                  :src="`https://republish.verbeterthuis.be/m/6055/9d4161bbb1b8/?event=7057&unique_conversion_id=${profId}`"
                  style="width: 1px; height: 1px; border: 0px"
                />
              </div>
            </div>
          </b-card>
        </b-row>
      </div>
    </b-container>
    <div class="hero bg-color py-4 py-md-5 mt-4">
      <b-container fluid="xl">
        <div class="px-xl-5 px-md-2 mx-lg-2 p-3">
          <b-row cols-md="2" cols="1" class="align-items-center">
            <b-col class="d-block d-md-none">
              <div>
                <img
                  src="../../assets/afspraak.png"
                  class="w-100 rounded mb-3"
                />
              </div>
            </b-col>
            <b-col class="text-start"
              ><div>
                <h1 class="fw-stronger formSection__form__whyme">
                  Waarom Mobilae?
                </h1>

                <h5 class="l-height formSection__form__whymeDesc">
                  Elke badkamer is anders. En ieders wensen zijn anders. Daarom
                  geven wij u graag een gratis persoonlijk advies bij u thuis
                  met maattekening. Onze adviseur bespreekt uw wensen en zet de
                  mogelijkheden op een rij. Dit persoonlijke advies is
                  vrijblijvend, objectief, deskundig en 100% afgestemd op uw
                  wensen en uw badkamer. Daarnaast hebben wij een grote keuze in
                  elke prijsklasse en is financiering ook mogelijk.
                </h5>
              </div>
            </b-col>
            <b-col class="d-none d-md-block">
              <div>
                <img src="../../assets/afspraak.png" class="w-100 rounded" />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AfterFormSubmited_badkamers",
  computed: mapGetters(["profId"]),
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss">
$enable-negative-margins: true;
.formSection {
  height: 684px;
}

.custom-select {
  appearance: revert !important;
}
.form-control {
  padding: 0.75rem 0.75rem !important;
}
.s-btn {
  padding: 1rem !important;
  width: 100%;
  background-color: #ff5a00 !important;
  border: 0 !important;
  border-radius: 15px !important;
  @media (min-width: 767px) and (max-width: 990px) {
    padding: 1rem !important;
  }
}
.book-img {
  width: 100%;
  @media (max-width: 767.9px) {
    width: 50% !important;
  }
}
.card {
  margin: 50px 0;
  border-radius: 10px !important;
}
.card-body {
  background-color: #50a89e !important;
  color: white;
  padding: 50px !important;
  border-radius: 7px;
  @media (max-width: 990px) {
    padding: 30px 15px !important;
  }
}
.opacity {
  opacity: 0.6;
}
label {
  padding: 7px 0;
}
@media (min-width: 768px) {
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
}
.bg-color {
  background-color: #b9e6ff !important;
}
.l-height {
  line-height: 1.9 !important;
}
.formSection {
  &__title {
    @media (max-width: 575px) {
      font-size: 1.3rem !important;
      margin-top: 10px !important;
    }
  }
  &__desc {
    @media (max-width: 575px) {
      font-size: 0.9rem !important;
      line-height: 1.6 !important;
    }
  }

  &__form {
    @media (max-width: 575px) {
      &__header {
        font-size: 1.5rem !important;
      }
      &__checkbox {
        font-size: 13px !important;
      }
      &__btn {
        font-size: 16px !important;
      }
      &__whyme {
        font-size: 1.3rem !important;
      }
      &__whymeDesc {
        font-size: 0.9rem !important;
        line-height: 1.6 !important;
      }
    }
  }
}
.desktop-sticker {
  top: -5%;
  right: 5%;
  z-index: 1;
}

.paragraph {
  @media (max-width: 575px) {
    text-align: justify;
  }
}
.image {
  @media (max-width: 575px) {
    float: right;
    margin: 0 10px 10px 0;
  }
}

// .mbl-image {
//   @media (max-width: 575px) {
//     position: absolute;
//     top: 66%;
//     z-index: 1;
//     left: 74%;
//   }
//   @media (max-width: 520px) {
//     position: absolute;
//     top: 68%;
//     z-index: 1;
//     left: 74%;
//   }
//   @media (max-width: 500px) {
//     position: absolute;
//     top: 66%;
//     z-index: 1;
//     left: 74%;
//   }
//   @media (max-width: 480px) {
//     position: absolute;
//     top: 64%;
//     z-index: 1;
//     left: 74%;
//   }
//   @media (max-width: 450px) {
//     position: absolute;
//     top: 62%;
//     z-index: 1;
//     left: 74%;
//   }
//   @media (max-width: 400px) {
//     position: absolute;
//     top: 63%;
//     z-index: 1;
//     left: 74%;
//   }
//   @media (max-width: 375px) {
//     position: absolute;
//     top: 63%;
//     z-index: 1;
//     left: 71%;
//   }
//   @media (max-width: 370px) {
//     position: absolute;
//     top: 61%;
//     z-index: 1;
//     left: 71%;
//   }
//   @media (max-width: 360px) {
//     position: absolute;
//     top: 67%;
//     z-index: 1;
//     left: 71%;
//   }
//   @media (max-width: 350px) {
//     position: absolute;
//     top: 71%;
//     z-index: 1;
//     left: 71%;
//   }
//   @media (max-width: 340px) {
//     position: absolute;
//     top: 70%;
//     z-index: 1;
//     left: 70%;
//   }
//   @media (max-width: 320.99px) {
//     position: absolute;
//     top: 70%;
//     z-index: 1;
//     left: 65%;
//   }
// }
.mbl-image {
  width: 150px;
  height: 161px;
  position: absolute;
  z-index: 1;
  margin-top: -30px;
  // @media (max-width: 667.99px) {
  //   width: 130px;
  //   height: 141px;
  //   margin-top: -15px;
  // }
  @media (max-width: 767.99px) {
    width: 121px;
    height: 131px;
    margin-top: -30px;
  }
  @media (max-width: 575.99px) {
    // margin-top: 10px;
    width: 91px;
    height: 100px;
    margin-top: -20px;
  }
  @media (max-width: 518.99px) {
    width: 101px;
    height: 110px;
    margin-top: -10px;
  }
  @media (max-width: 400.99px) {
    width: 81px;
    height: 90px;
    margin-top: 2%;

    right: 10%;
  }

  @media (max-width: 342.99px) {
    width: 81px;
    height: 90px;

    margin-top: 15%;
  }
}
</style>
